import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import DefaultLayout from "../components/default-page-layout"
import ImgSharpInline from "../components/ImgSharpInline.js"
import rehypeReact from "rehype-react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const renderAst = new rehypeReact({
  Fragment: React.Fragment,
  createElement: React.createElement,
  components: { "img-sharp-inline": ImgSharpInline },
}).Compiler

export default function staticPageTemplate({ data }) {
  //data.markdownRemark holds your post data
  return (
    <>
      <DefaultLayout>
        <article className="prose mx-auto lg:prose-xl py-4">
          <GatsbySeo
          title={data.ghostPost.meta_title}
          description={data.ghostPost.meta_description}/>
          <h1>{data.ghostPost.title}</h1>
          {renderAst(data.ghostPost.childHtmlRehype.htmlAst)}
        </article>
      </DefaultLayout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      title
      meta_title
      meta_description
      childHtmlRehype {
        htmlAst
      }
    }
  }
`
